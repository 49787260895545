<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <!-- Later I will convert into env variable for now just set to the -->
        <a
          class="d-flex justify-content-center mb-2"
          :href="frontUrl || 'https://dev.globalenglishcampus.com'"
        >
          <b-img
            fluid
            :src="$store.state.app.companyLogo"
            alt="Login V2"
            class="col-7 col-xl-5"
          />
        </a>
        <b-card-title
          class="mb-1 font-weight-bold"
          title-tag="h2"
        >
          {{ companyName ? $t('messages.welcome-to', {name: companyName}) :$t('messages.welcome') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('messages.please-sign-in') }}
        </b-card-text>
        <div class="d-flex justify-content-center ">
          <div class="pb-1">
            <ul style="list-style-type: none;display: contents;">
              <locale
                :key="defaultLocale"
                :default-locale="defaultLocale"
              />
            </ul>
          </div>
        </div>

        <!-- form -->
        <validation-observer
          ref="loginForm"
        >
          <b-form
            class="auth-login-form mt-1"
            @submit.prevent="login"
          >
            <b-row
              v-if="isRegistered"
              class="pb-2 p-1"
            >
              <span
                class="text-success"
              >
                {{ $t('labels.password-sent') }}
              </span>
            </b-row>

            <!-- email -->
            <b-form-group
              :label="$t('email-phone')"
              label-for="login-email"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="$t('email-phone')"
                vid="email"
                rules="required|max:256"
              >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="john@example.com"
                  @keydown.space.prevent
                  @paste="onEmailPaste"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t('password') }}</label>
                <b-link
                  :disabled="isProcessing"
                  :to="{ name: 'forgot-password' }"
                >
                  <small>{{ $t('actions.forgot-password') }}</small>
                </b-link>
              </div>
              <validation-provider
                v-slot="{ errors }"
                :name="$t('password')"
                vid="password"
                rules="required|max:256"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <!-- <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                {{ $t('actions.remember-me') }}
              </b-form-checkbox>
            </b-form-group> -->

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="isProcessing||!(!!email || !!password)"
            >
              <b-spinner
                v-show="isProcessing"
                class="mr-1"
                small
                variant="light"
              />
              <span>{{ $t('actions.sign-in') }}</span>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>{{ $t('messages.new-to-our-platform') }} </span>
          <b-link
            :disabled="isProcessing"
            :to="{ name: 'register' }"
          >
            <span>&nbsp;{{ $t('actions.create-new-account') }}</span>
          </b-link>
        </b-card-text>
        <!-- <div class="text-center">
          <span>OR</span>
        </div> -->
        <div v-if="!companyName"
             class="text-center"
        >
          <b-button
            variant="outline-primary"
            @click="showGuestLogin = true"
          >
            {{ $t('login-guest') }}
          </b-button>
        </div>
        <!-- divider -->
        <div v-if="false">
          <div class="divider my-2">
            <div class="divider-text">
              {{ $t('or') }}
            </div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
              :disabled="isProcessing"
              @click="loginWithFacebook()"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
              :disabled="isProcessing"
              @click="loginWithGoogle()"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
          </div>
        </div>
        <div class="d-flex justify-content-end py-1">
          <a
            target="_"
            href="/privacy-policy"
          >{{ $t('navigation-menu.privacy-policy') }}</a>
        </div>
      </b-card>
      <!-- /Login v1 -->
    </div>
    <guest-login-modal
      :show="showGuestLogin"
      @close="showGuestLogin = false"
      @afterLogin="afterLogin"
    />
  </div>
</template>

<script>

import {
  BSpinner,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  // BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
  BCard,
  BRow,
} from 'bootstrap-vue'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import i18n from '@/libs/i18n'

import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import getLandingPageFor from '@/router/utils'
import { initFbsdk, getUserDetails } from '@/auth/services/fb'
import { initGoogleSdk } from '@/auth/services/google'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import afterLogin from './afterLogin'
import GuestLoginModal from './GuestLoginModal.vue'
import { nativeAppFcmLogin } from './nativeAppFcmHandler'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BSpinner,
    BInputGroup,
    // BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BRow,
    ValidationProvider,
    ValidationObserver,
    Locale,
    GuestLoginModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.png'),

      // validation rules
      required,
      email,
      isProcessing: false,
      isRegistered: false,
      showGuestLogin: false,
    }
  },
  computed: {
    frontUrl() {
      return this.$store.state.app.partnerCompanyInfo?.cms_domain || window.location.origin.replace('edu.', '')
    },
    companyName() {
      return this.$store.state.app.partnerCompanyInfo?.name
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    defaultLocale() {
      return this.$store.state.app.partnerCompanyInfo?.default_locale || 'en'
    },
  },
  created() {
    initFbsdk()
    initGoogleSdk()
  },
  mounted() {
    this.isRegistered = this.$route.params.isRegistered
    this.unRegisterTHeCache()
  },
  methods: {
    unRegisterTHeCache() {
      if ('serviceWorker' in navigator) {
        caches.keys().then(cacheNames => Promise.all(cacheNames.map(cache => caches.delete(cache)))).then(() => {
          // TODO: remove the console log
          console.info('All caches cleared!')
        })
      }
    },
    onEmailPaste(data) {
      setTimeout(() => {
        const val = data.target.value
        this.userEmail = val.replace(/\s/g, '')
      }, 10)
    },
    afterLogin(response) {
      afterLogin(response, this)
    },
    login() {
      nativeAppFcmLogin()
      this.userEmail = this.userEmail.replace(/\s/g, '')
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then(response => {
              this.afterLogin(response)
            })
            .catch(error => {
              if (error.response?.data) {
                if (error.response?.data?.errors) this.$refs.loginForm.setErrors(error.response.data.errors)
                if (error.response?.data?.message) this.showErrorMessage(error)
              } else this.showErrorMessage(error)
            }).finally(() => {
              this.isProcessing = false
            })
        }
      })
    },
    loginWithFacebook() {
      this.isProcessing = true
      window.FB.login(async () => {
        const details = await getUserDetails()
        // eslint-disable-next-line no-shadow
        const { id, name, email } = details
        const [firstname, lastname] = name.split(' ')
        const avatar = details.picture.data.url
        useJwt.socialLogin('facebook', {
          provider_id: id,
          name,
          email,
          avatar,
          firstname,
          lastname,
        }).then(res => {
          this.setUserAndRedirect(res)
        })
      }).finally(() => {
        this.isProcessing = false
      })
    },
    loginWithGoogle() {
      this.isProcessing = true
      window.gapi.auth2.getAuthInstance().signIn().then(response => {
        const details = response.getBasicProfile()
        const fullName = details.getName()
        const names = fullName.split(' ')
        const user = {
          provider_id: details.getId(),
          email: details.getEmail(),
          name: details.getName(),
          avatar: details.getImageUrl(),
          firstname: names[0],
          lastname: names.length > 0 ? names[1] : '',
        }

        useJwt.socialLogin('google', user).then(res => {
          this.setUserAndRedirect(res)
        }).catch(() => {
          this.isProcessing = false
        })
      }).catch(() => {
        this.isProcessing = false
      })
    },
    setUserAndRedirect(res) {
      this.isProcessing = false
      const userData = res.data.data.user
      useJwt.setToken(res.data.data.accessToken)
      localStorage.setItem('userData', JSON.stringify(userData))
      this.$router.replace({ name: getLandingPageFor(userData.usertype, 'home') }).then(() => {
        this.$store.dispatch('authEvents/onUserLogin')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${i18n.tc('welcome')} ${userData.firstname} ${userData.lastname}`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `${i18n.tc('messages.successfully-logged-in-as')} ${userData.firstname} ${userData.lastname}.`,
          },
        }, {
          position: window.innerWidth <= 576 ? 'top-center' : 'bottom-left',
        })
      })
    },
  },

}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped lang="css">
@media (max-width: 576px) {
  .auth-bg{
     padding-top:80px;
    }
    .brand-text{
      font-size: 20px;
    }
}
</style>
